import { useRouter } from 'next/router';
import { fetcherFull, MethodEnum } from 'utils/helpers/browser/newFetcher';
import { getDiscipline } from 'utils/helpers/browser/discipline';
import { getRoute } from 'constants/routes';

export const performRecoveryByLogin = async (
    username: string,
    password: string,
    linked: boolean,
    router: ReturnType<typeof useRouter>
) => {
    const confirmationUrl = `${location.origin}/person-recovery/confirm`;

    const data: any = {
        data: {
            username,
            password,
            linked,
            confirmation_url: confirmationUrl,
        },
    };

    const response = await fetcherFull<any>(
        '/api/person_recovery/by_is1_login',
        MethodEnum.POST,
        undefined,
        data
    );

    if (response.error) {
        console.error('Recovery failed:', response.error);
        throw new Error(response.error);
    }

    if (response.data.email_needed) {
        await router.push(
            getRoute('/person-recovery/email', [], null, {
                discipline: getDiscipline(),
                emailSecret: response.data.email_secret,
                emailRecommendation: response.data.email_recommendation,
            })
        );
    } else {
        return true; // Obnova úspěšná
    }
};
